import React from "react"
import Arrow from "../../images/projects/arrow.svg"
function Heading({ text, arc = 120, radius = 400 }) {
  const characters = text.split("")
  const degree = arc / characters.length

  return (
    <h1 className="text-violet-400 cursor-pointer text-xs ">
      <div className="relative animate-rotate text-center ">
        {characters.map((char, i) => (
          <span
            className=" absolute top-[-4.2rem]"
            key={`heading-span-${i}`}
            style={{
              height: `${radius}px`,
              transform: `rotate(${degree * i - arc / 2}deg)`,
              transformOrigin: `0 ${radius}px 0`,
            }}
          >
            {char}
          </span>
        ))}
      </div>

      <img
        alt="arrow"
        src={Arrow}
        width={40}
        height={40}
        className=" hover:rotate-[45deg] duration-500 transition-transform translate-y-[-1.25rem] translate-x-[-0.06rem]"
      />
    </h1>
  )
}

export default Heading
