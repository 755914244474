import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useRef, useState } from "react"
import Heading from "../../components/Projects/CircleText"
import Scrollbar from "smooth-scrollbar"
import { Helmet } from "react-helmet"
const Stepwall = () => {
  const ref = useRef(null)
  const [offsetY, setOffsetY] = useState(0)
  useEffect(() => {
    const options = {
      damping: 0.07,
    }

    const scroll = document.getElementById("scroll")
    const scrollBar = Scrollbar.init(scroll, options)
    window.addEventListener("scroll", () => setOffsetY(window.pageYOffse))

    return window.removeEventListener("scroll", () =>
      setOffsetY(scrollBar.offset)
    )
  }, [])

  return (
    <div ref={ref} id="scroll" data-scrollbar>
      <Helmet title="Ali Hammad | Stepwall">
        <title>Stepwall</title>
        <meta
          name="description"
          content="Realestate React web application based in Dubai. You can buy or rent diverse properties"
        />
      </Helmet>
      <div className="overflow-hidden px-4 sm:px-12 md:px-16 lg:px-20 mx-auto mt-10 lg:mt-20 ">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://realestate-steel.vercel.app/"
          className="absolute top-[33rem] sm:top-[40rem] md:top-[50rem] lg:top-[30rem] translate-x-[-50%] left-[50%] lg:left-40"
        >
          <Heading
            text="LIVE DEMO * LIVE DEMO * LIVE DEMO * "
            arc={360}
            radius={70}
          />
        </a>
        <div className=" w-full flex flex-col lg:flex-row lg:justify-between">
          <div className=" animate-textreveal h-20 lg:h-32 w-[30rem] overflow-clip ">
            <h2 className=" text-white text-5xl lg:text-9xl tracking-wide">
              STEPWALL
            </h2>
          </div>

          <div className="w-full lg:w-[50%] h-[16rem] sm:translate-y-6 sm:h-[22rem] md:h-[25rem] lg:h-[30rem] overflow-hidden ">
            <StaticImage
              className="object-cover w-full h-full animate-imagereveal"
              src="../../images/projects/webp/stepwall.webp"
              alt="stepwall"
            />
          </div>
        </div>
        <div className="mt-72 md:mt-96 lg:mt-40 flex flex-col lg:flex-row">
          <div className="w-full lg:w-[50%]">
            <h4 className="text-white text-3xl font-medium opacity-90">
              About
            </h4>
            <p className="mb-10 text-violet-200 text-sm text-justify lg:w-[60%]  leading-6 opacity-50 mt-6 font-light">
              Stepwall is a property service that allow you to discover
              realestates in Dubai. Just open the website and you can see
              different available options like Houses, apartments, offices, and
              more. It's a good choice for the new comers or tourist who wants
              to visit or live in Dubai
            </p>
          </div>
          <div className="w-full lg:w-[50%]">
            <h4 className="text-white text-3xl font-medium opacity-90">
              Role & Technologies
            </h4>
            <p className="text-violet-200 text-sm lg:w-[60%] text-justify  leading-6 opacity-50 mt-6 font-light">
              In this project, I had to focus on the development side more than
              design, So I decided to use Material Ui and React (Next.js) as the
              development frameworks. You can filter items, and see the details.
              The data has been fetched from Rapid API, which is a good option
              to have different data to projects like this one.
            </p>
          </div>
        </div>
        <div className="w-full mt-2 md:mt-10 overflow-hidden z-10">
          <StaticImage
            className="object-cover w-full h-full"
            src="../../images/projects/webp/stepwall-sm.webp"
            alt="stepwall"
          />
        </div>
        <div className="w-full mt-9 overflow-hidden z-10">
          <StaticImage
            className="object-cover w-full h-full"
            src="../../images/projects/webp/stepwall2.webp"
            alt="stepwall"
          />
        </div>
      </div>
    </div>
  )
}

export default Stepwall
